<template>
  <AchievementsTable />
</template>

<script>
import AchievementsTable from '@/views/ziqni/achievements/AchievementsTable';

export default {
  name: 'Achievement',
  components: {
    AchievementsTable,
  },
}
</script>

<style lang="scss">
.achievements {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>

